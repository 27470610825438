import { useEffect, useState } from 'react';

const useScrollbarWidth = (): string => {
  const [scrollbarWidth, setScrollbarWidth] = useState('0px');
  useEffect(() => {
    if (typeof window != 'undefined') {
      const width = window.innerWidth - document.documentElement.clientWidth;
      setScrollbarWidth(width ? width / 2 + 'px' : '0px');
    }
  }, []);

  return scrollbarWidth;
};

export default useScrollbarWidth;
