import './NewButtonComponent.scss';

import React from 'react';

import Icon from '../ui/Icon/Icon';
import Link from '../ui/Link/Link';

interface IButtonProps {
  label: string;
  icon?: string;
  variant?: TButtonVariants;
  extraClass?: string;
  onClick?: () => void;
  linkTo?: string | { path: string; template: string; pageScope?: string };
  linkType?: string;
  linkParam?: string;
}

export type TButtonVariants = '' | 'opaque' | 'outlined' | 'white' | 'light';

const NewButtonComponent: React.FC<IButtonProps> = ({
  variant,
  label,
  icon,
  extraClass,
  onClick,
  linkTo,
  linkType,
  linkParam
}) => {
  const content = (
    <div className="NewButtonComponent__buttonInner">
      {icon && <Icon type={icon} />}
      <span>{label}</span>
    </div>
  );
  return (
    <button
      onClick={onClick}
      className={`NewButtonComponent${variant ? ' NewButtonComponent--' + variant : ''} ${extraClass || ''}`}
    >
      {linkTo ? (
        <Link linkTo={linkTo} linkType={linkType} linkParam={linkParam}>
          {content}
        </Link>
      ) : (
        content
      )}
    </button>
  );
};

export default NewButtonComponent;
