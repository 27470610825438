import './Phv2025StageComponent.scss';

import React from 'react';

import useBreakpoint from '../../hooks/useBreakpoint';
import Background from '../../styles/assets/images/phv-2025-stage/static-Phv25_background.webp';
import Barbara from '../../styles/assets/images/phv-2025-stage/static-Phv25_barbara.webp';
import BarbaraMobile from '../../styles/assets/images/phv-2025-stage/static-Phv25_barbara-mobile.webp';
import Signet from '../../styles/assets/images/phv-2025-stage/static-Phv25_signet.webp';
import Trusted from '../../styles/assets/images/phv-2025-stage/static-Phv25_trusted.webp';
import NewButtonComponent from '../NewButtonComponent/NewButtonComponent';
import Image from '../ui/Image/Image';

const Phv2025StageComponent = () => {
  const isMaxVpS = useBreakpoint('s');
  const isMaxVpXS = useBreakpoint('xs');

  return (
    <header className={`Phv2025StageComponent`}>
      <div className="Phv2025StageComponent__inner">
        <div className="Phv2025StageComponent__contentWrapper">
          {isMaxVpS ? (
            <div className="Phv2025StageComponent__headline">
              <p>EINE FÜR ALLE</p>
              <h1>
                Die <strong>Privathaftpflicht</strong>
                <br /> der Spitzenklasse
              </h1>
            </div>
          ) : (
            <h1 className="Phv2025StageComponent__headline">
              Eine für alle: <br /> Die <strong>Privathaftpflicht</strong>
              <br /> der Spitzenklasse
            </h1>
          )}
          <div className="Phv2025StageComponent__buttonWrapper">
            {isMaxVpS && (
              <div className="Phv2025StageComponent__signetWrapper">
                <Image extraClass="Phv2025StageComponent__signet" src={Signet} />
              </div>
            )}
            <NewButtonComponent
              icon="calculator"
              linkTo={'/versicherungen/haftpflichtversicherung/privat/tarifrechner?appView=true'}
              label="Jetzt berechnen"
              linkType="external"
            />
            <NewButtonComponent
              icon="arrow-right"
              linkTo={'/versicherungen/haftpflichtversicherung/privat'}
              linkType="external"
              label="Mehr erfahren"
              variant="white"
            />
          </div>
          <div className="Phv2025StageComponent__trustedWrapper">
            <Image src={Trusted} />
          </div>
        </div>
        <Image extraClass="Phv2025StageComponent__background" src={Background} />
        <Image
          extraClass="Phv2025StageComponent__barbara"
          objectFitContain
          src={isMaxVpXS ? BarbaraMobile : Barbara}
        />
        {!isMaxVpS && <Image extraClass="Phv2025StageComponent__signet" src={Signet} />}
      </div>
    </header>
  );
};

export default Phv2025StageComponent;
