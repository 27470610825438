import React, { useContext } from 'react';

import Columns from '../../components/ui/Columns/Columns';
import Icon from '../../components/ui/Icon/Icon';
import Image from '../../components/ui/Image/Image';
import Tooltip from '../../components/ui/Tooltip/Tooltip';
import { CalculatorSeoPageContext } from '../../containers/CalculatorSeoPage';

interface ICalculatorSeoPageStageArea {
  desc: string;
  popup: string;
  price: string;
  calculatorLabel: string;
  image: any;
}

const CalculatorSeoPageStageArea: React.FC<ICalculatorSeoPageStageArea> = ({
  desc,
  popup,
  price,
  calculatorLabel,
  image
}) => {
  const { headline, setIsCalculatorVisible } = useContext(CalculatorSeoPageContext) || {};

  return (
    <section className="l-content__section l-content__section--wide l-landingseo__header">
      <div>
        <Columns widths={[1, 1]}>
          <div>
            <Image src={image} sizes={{ w: 400 }} />
          </div>
          <div>
            <h1 className="text-uppercase">{headline}</h1>
            <p className="spacer">{desc}</p>
            <span className="m-button m-button--flagged">
              {price && (
                <Tooltip
                  extraClassTooltip="m-button__flag m-button__flag--price"
                  extraClassPopup="m-tooltip__popup--wide m-tooltip__popup--unmasked"
                  label={
                    <span>
                      Bereits ab mtl.
                      <strong> {price}€*</strong>
                    </span>
                  }
                >
                  {popup}
                </Tooltip>
              )}
              <a onClick={() => setIsCalculatorVisible(true)} className="m-button m-button--size-m">
                <Icon type="calculator" className="ico-calculator"></Icon>
                <span>{calculatorLabel || 'Jetzt Tarif berechnen'}</span>
              </a>
            </span>
          </div>
        </Columns>
      </div>
    </section>
  );
};

export default CalculatorSeoPageStageArea;
